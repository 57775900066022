import React, {useEffect, useRef, useState} from 'react'
import { gsap } from "gsap";

export const AboutSVG = (props) => {

    const svgStyle = {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: '2'
    }

    // {styleA} 
    const styleA = {
        fill: 'rgb(1,123,179)',
        fillRule: 'nonnzero'
    }

    //{styleB} 
    const styleB = {
        fill: 'rgb(239,162,38)',
        fillRule: 'nonzero'
    }



      return (
        <svg width="100%" viewBox="0 0 1358 219" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" xmlnsserif="http://www.serif.com/" style={svgStyle}>
            <g transform="matrix(1,0,0,1,-687,-677)">
                <g transform="matrix(0.437931,0,0,0.721425,372.945,-437.513)">
                    <g transform="matrix(196.023,0,0,196.023,720.888,1677.24)">
                        <path d="M-0.013,-0.667L0.238,-0L0.553,-0L0.685,-0.489L0.819,-0L1.132,-0L1.391,-0.667L1.176,-0.667L0.996,-0.168L0.958,-0.168L0.806,-0.667L0.561,-0.667L0.41,-0.168L0.373,-0.168L0.194,-0.667L-0.013,-0.667Z" style={styleB}/>
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,983.754,1677.24)">
                        <path d="M0.362,-0.485C0.201,-0.485 0.132,-0.474 0.088,-0.442C0.041,-0.408 0.027,-0.363 0.027,-0.252C0.027,-0.115 0.038,-0.071 0.082,-0.035C0.127,0.002 0.186,0.012 0.363,0.012C0.538,0.012 0.597,0.002 0.642,-0.035C0.685,-0.071 0.697,-0.115 0.697,-0.245C0.697,-0.364 0.684,-0.407 0.636,-0.442C0.592,-0.474 0.524,-0.485 0.362,-0.485ZM0.363,-0.352C0.413,-0.352 0.455,-0.347 0.477,-0.338C0.51,-0.325 0.52,-0.302 0.52,-0.238C0.52,-0.138 0.497,-0.121 0.363,-0.121C0.306,-0.121 0.266,-0.126 0.244,-0.136C0.212,-0.149 0.204,-0.17 0.204,-0.233C0.204,-0.334 0.228,-0.352 0.363,-0.352Z" style={styleB}/>
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,1125.48,1677.24)">
                        <path d="M0.678,-0L0.678,-0.473L0.501,-0.473L0.501,-0.283C0.501,-0.195 0.495,-0.17 0.47,-0.148C0.448,-0.129 0.416,-0.122 0.353,-0.122C0.286,-0.122 0.259,-0.128 0.238,-0.148C0.219,-0.166 0.214,-0.188 0.214,-0.257L0.214,-0.473L0.037,-0.473L0.037,-0.257C0.037,-0.128 0.046,-0.092 0.089,-0.048C0.132,-0.005 0.19,0.012 0.3,0.012C0.419,0.012 0.475,-0.011 0.502,-0.071L0.511,-0.071L0.511,-0L0.678,-0Z" style={styleB}/>
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,1266.03,1677.24)">
                        <rect x="0.04" y="-0.667" width="0.177" height="0.667" style={styleB}/>
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,1316.4,1677.24)">
                        <path d="M0.693,-0.667L0.516,-0.667L0.516,-0.41L0.509,-0.41C0.475,-0.47 0.434,-0.486 0.31,-0.486C0.188,-0.486 0.139,-0.478 0.096,-0.45C0.046,-0.417 0.027,-0.36 0.027,-0.24C0.027,-0.122 0.039,-0.074 0.078,-0.038C0.119,-0.001 0.18,0.012 0.308,0.012C0.436,0.012 0.483,-0.005 0.517,-0.062L0.526,-0.062L0.526,-0L0.693,-0L0.693,-0.667ZM0.368,-0.353C0.494,-0.353 0.517,-0.335 0.517,-0.237C0.517,-0.182 0.51,-0.157 0.49,-0.144C0.47,-0.13 0.422,-0.122 0.357,-0.122C0.224,-0.122 0.204,-0.138 0.204,-0.242C0.204,-0.337 0.228,-0.353 0.368,-0.353Z" style={styleB}/>
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,1460.09,1677.24)">
                        <path d="M0.04,-0.473L0.04,-0L0.217,-0L0.217,-0.189C0.217,-0.276 0.221,-0.298 0.243,-0.321C0.264,-0.343 0.299,-0.352 0.364,-0.352C0.43,-0.352 0.464,-0.345 0.483,-0.326C0.502,-0.308 0.505,-0.291 0.505,-0.215L0.505,-0L0.682,-0L0.682,-0.215C0.682,-0.345 0.673,-0.381 0.63,-0.426C0.588,-0.469 0.53,-0.485 0.417,-0.485C0.302,-0.485 0.248,-0.462 0.216,-0.401L0.207,-0.401L0.207,-0.473L0.04,-0.473Z" style={styleB}/>
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,1600.25,1677.24)">
                        <path d="M0.068,-0.667L0.068,-0.469L0.123,-0.469L0.123,-0.45C0.123,-0.411 0.108,-0.395 0.073,-0.395L0.04,-0.395L0.04,-0.29C0.062,-0.289 0.074,-0.289 0.091,-0.289C0.199,-0.289 0.229,-0.297 0.255,-0.329C0.275,-0.354 0.281,-0.389 0.281,-0.473L0.281,-0.667L0.068,-0.667Z" style={styleB}/>
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,1666.31,1677.24)">
                        <path d="M0.093,-0.582L0.093,-0.473L0,-0.473L0,-0.346L0.093,-0.346L0.093,-0.208C0.093,-0.169 0.093,-0.145 0.094,-0.137C0.096,-0.083 0.106,-0.057 0.131,-0.032C0.166,0.002 0.212,0.012 0.325,0.012C0.447,0.012 0.501,-0.003 0.537,-0.048C0.564,-0.083 0.571,-0.112 0.573,-0.202L0.411,-0.202C0.411,-0.153 0.41,-0.149 0.404,-0.138C0.396,-0.121 0.376,-0.114 0.34,-0.114C0.306,-0.114 0.288,-0.121 0.279,-0.137C0.271,-0.149 0.27,-0.156 0.27,-0.208L0.27,-0.346L0.55,-0.346L0.55,-0.473L0.27,-0.473L0.27,-0.582L0.093,-0.582Z" style={styleB}/>
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,1828.81,1677.24)">
                        <path d="M-0.02,-0.667L0.362,-0.236L0.362,-0L0.572,-0L0.572,-0.236L0.937,-0.667L0.672,-0.667L0.464,-0.4L0.247,-0.667L-0.02,-0.667Z" style={styleB}/>
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,1990.72,1677.24)">
                        <path d="M0.362,-0.485C0.201,-0.485 0.132,-0.474 0.088,-0.442C0.041,-0.408 0.027,-0.363 0.027,-0.252C0.027,-0.115 0.038,-0.071 0.082,-0.035C0.127,0.002 0.186,0.012 0.363,0.012C0.538,0.012 0.597,0.002 0.642,-0.035C0.685,-0.071 0.697,-0.115 0.697,-0.245C0.697,-0.364 0.684,-0.407 0.636,-0.442C0.592,-0.474 0.524,-0.485 0.362,-0.485ZM0.363,-0.352C0.413,-0.352 0.455,-0.347 0.477,-0.338C0.51,-0.325 0.52,-0.302 0.52,-0.238C0.52,-0.138 0.497,-0.121 0.363,-0.121C0.306,-0.121 0.266,-0.126 0.244,-0.136C0.212,-0.149 0.204,-0.17 0.204,-0.233C0.204,-0.334 0.228,-0.352 0.363,-0.352Z" style={styleB}/>
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,2132.45,1677.24)">
                        <path d="M0.678,-0L0.678,-0.473L0.501,-0.473L0.501,-0.283C0.501,-0.195 0.495,-0.17 0.47,-0.148C0.448,-0.129 0.416,-0.122 0.353,-0.122C0.286,-0.122 0.259,-0.128 0.238,-0.148C0.219,-0.166 0.214,-0.188 0.214,-0.257L0.214,-0.473L0.037,-0.473L0.037,-0.257C0.037,-0.128 0.046,-0.092 0.089,-0.048C0.132,-0.005 0.19,0.012 0.3,0.012C0.419,0.012 0.475,-0.011 0.502,-0.071L0.511,-0.071L0.511,-0L0.678,-0Z" style={styleB}/>
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,2322,1677.24)">
                        <path d="M0.047,-0.667L0.047,-0L0.734,-0L0.734,-0.17L0.26,-0.17L0.26,-0.667L0.047,-0.667Z" style={styleB}/>
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,2465.88,1677.24)">
                        <path d="M0.04,-0.473L0.04,-0L0.217,-0L0.217,-0.473L0.04,-0.473ZM0.04,-0.667L0.04,-0.534L0.217,-0.534L0.217,-0.667L0.04,-0.667Z" style={styleB}/>
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,2516.26,1677.24)">
                        <path d="M0.04,-0.667L0.04,-0L0.217,-0L0.217,-0.19L0.262,-0.19L0.452,-0L0.687,-0L0.409,-0.253L0.654,-0.473L0.417,-0.473L0.255,-0.319L0.217,-0.319L0.217,-0.667L0.04,-0.667Z" style={styleB}/>
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,2643.48,1677.24)">
                        <path d="M0.487,-0.151C0.48,-0.109 0.459,-0.101 0.346,-0.101C0.282,-0.101 0.245,-0.107 0.226,-0.121C0.209,-0.134 0.204,-0.15 0.204,-0.198L0.666,-0.198C0.667,-0.218 0.667,-0.24 0.667,-0.246C0.667,-0.353 0.651,-0.406 0.609,-0.441C0.566,-0.476 0.51,-0.486 0.35,-0.486C0.196,-0.486 0.137,-0.476 0.091,-0.444C0.041,-0.409 0.027,-0.362 0.027,-0.235C0.027,-0.114 0.041,-0.067 0.087,-0.032C0.13,-0 0.195,0.012 0.332,0.012C0.585,0.012 0.65,-0.02 0.663,-0.151L0.487,-0.151ZM0.204,-0.295C0.206,-0.361 0.234,-0.376 0.353,-0.376C0.423,-0.376 0.459,-0.369 0.475,-0.352C0.486,-0.341 0.488,-0.332 0.489,-0.295L0.204,-0.295Z" style={styleB}/>
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,2828.33,1677.24)">
                        <path d="M0,-0.667L0,-0.497L0.297,-0.497L0.297,-0L0.51,-0L0.51,-0.497L0.807,-0.497L0.807,-0.667L0,-0.667Z" style={styleB}/>
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,2961.23,1677.24)">
                        <path d="M0.362,-0.485C0.201,-0.485 0.132,-0.474 0.088,-0.442C0.041,-0.408 0.027,-0.363 0.027,-0.252C0.027,-0.115 0.038,-0.071 0.082,-0.035C0.127,0.002 0.186,0.012 0.363,0.012C0.538,0.012 0.597,0.002 0.642,-0.035C0.685,-0.071 0.697,-0.115 0.697,-0.245C0.697,-0.364 0.684,-0.407 0.636,-0.442C0.592,-0.474 0.524,-0.485 0.362,-0.485ZM0.363,-0.352C0.413,-0.352 0.455,-0.347 0.477,-0.338C0.51,-0.325 0.52,-0.302 0.52,-0.238C0.52,-0.138 0.497,-0.121 0.363,-0.121C0.306,-0.121 0.266,-0.126 0.244,-0.136C0.212,-0.149 0.204,-0.17 0.204,-0.233C0.204,-0.334 0.228,-0.352 0.363,-0.352Z" style={styleB}/>
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,3151.96,1677.24)">
                        <path d="M0.047,-0.667L0.047,-0L0.25,-0L0.25,-0.255L0.361,-0.255L0.66,-0L0.968,-0L0.55,-0.34L0.936,-0.671L0.625,-0.671L0.361,-0.425L0.25,-0.425L0.25,-0.667L0.047,-0.667Z" style={styleB}/>
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,3337.79,1677.24)">
                        <path d="M0.04,-0.473L0.04,-0L0.217,-0L0.217,-0.189C0.217,-0.276 0.221,-0.298 0.243,-0.321C0.264,-0.343 0.299,-0.352 0.364,-0.352C0.43,-0.352 0.464,-0.345 0.483,-0.326C0.502,-0.308 0.505,-0.291 0.505,-0.215L0.505,-0L0.682,-0L0.682,-0.215C0.682,-0.345 0.673,-0.381 0.63,-0.426C0.588,-0.469 0.53,-0.485 0.417,-0.485C0.302,-0.485 0.248,-0.462 0.216,-0.401L0.207,-0.401L0.207,-0.473L0.04,-0.473Z" style={styleB}/>
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,3478.73,1677.24)">
                        <path d="M0.362,-0.485C0.201,-0.485 0.132,-0.474 0.088,-0.442C0.041,-0.408 0.027,-0.363 0.027,-0.252C0.027,-0.115 0.038,-0.071 0.082,-0.035C0.127,0.002 0.186,0.012 0.363,0.012C0.538,0.012 0.597,0.002 0.642,-0.035C0.685,-0.071 0.697,-0.115 0.697,-0.245C0.697,-0.364 0.684,-0.407 0.636,-0.442C0.592,-0.474 0.524,-0.485 0.362,-0.485ZM0.363,-0.352C0.413,-0.352 0.455,-0.347 0.477,-0.338C0.51,-0.325 0.52,-0.302 0.52,-0.238C0.52,-0.138 0.497,-0.121 0.363,-0.121C0.306,-0.121 0.266,-0.126 0.244,-0.136C0.212,-0.149 0.204,-0.17 0.204,-0.233C0.204,-0.334 0.228,-0.352 0.363,-0.352Z" style={styleB}/>
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,3619.87,1677.24)">
                        <path d="M-0.01,-0.473L0.165,-0L0.401,-0L0.496,-0.278L0.589,-0L0.83,-0L1.005,-0.473L0.817,-0.473L0.706,-0.135L0.579,-0.473L0.411,-0.473L0.285,-0.129L0.177,-0.473L-0.01,-0.473Z" style={styleB}/>
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,720.888,1846)">
                        <path d="M0.047,-0.667L0.047,-0L0.247,-0L0.242,-0.499L0.278,-0.499L0.529,-0L0.698,-0L0.949,-0.499L0.983,-0.499L0.978,-0L1.178,-0L1.178,-0.667L0.812,-0.667L0.613,-0.234L0.415,-0.667L0.047,-0.667Z" style={styleB}/>
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,960.82,1846)">
                        <path d="M0.487,-0.151C0.48,-0.109 0.459,-0.101 0.346,-0.101C0.282,-0.101 0.245,-0.107 0.226,-0.121C0.209,-0.134 0.204,-0.15 0.204,-0.198L0.666,-0.198C0.667,-0.218 0.667,-0.24 0.667,-0.246C0.667,-0.353 0.651,-0.406 0.609,-0.441C0.566,-0.476 0.51,-0.486 0.35,-0.486C0.196,-0.486 0.137,-0.476 0.091,-0.444C0.041,-0.409 0.027,-0.362 0.027,-0.235C0.027,-0.114 0.041,-0.067 0.087,-0.032C0.13,-0 0.195,0.012 0.332,0.012C0.585,0.012 0.65,-0.02 0.663,-0.151L0.487,-0.151ZM0.204,-0.295C0.206,-0.361 0.234,-0.376 0.353,-0.376C0.423,-0.376 0.459,-0.369 0.475,-0.352C0.486,-0.341 0.488,-0.332 0.489,-0.295L0.204,-0.295Z" style={styleB}/>
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,1145.67,1846)">
                        <path d="M0.047,-0L0.527,-0C0.751,-0.001 0.762,-0.002 0.8,-0.015C0.859,-0.035 0.884,-0.084 0.884,-0.179C0.884,-0.286 0.857,-0.325 0.769,-0.34C0.836,-0.359 0.859,-0.398 0.859,-0.493C0.859,-0.614 0.823,-0.656 0.714,-0.664C0.684,-0.666 0.684,-0.666 0.526,-0.667L0.047,-0.667L0.047,-0ZM0.24,-0.404L0.24,-0.51L0.526,-0.51C0.618,-0.509 0.621,-0.509 0.632,-0.507C0.653,-0.502 0.662,-0.487 0.662,-0.459C0.662,-0.435 0.655,-0.418 0.64,-0.412C0.627,-0.406 0.598,-0.404 0.526,-0.404L0.24,-0.404ZM0.24,-0.16L0.24,-0.271L0.527,-0.271C0.645,-0.27 0.645,-0.27 0.655,-0.268C0.678,-0.263 0.687,-0.249 0.687,-0.218C0.687,-0.19 0.68,-0.175 0.663,-0.168C0.648,-0.161 0.648,-0.161 0.527,-0.16L0.24,-0.16Z" style={styleB}/>
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,1323.66,1846)">
                        <path d="M0.487,-0.151C0.48,-0.109 0.459,-0.101 0.346,-0.101C0.282,-0.101 0.245,-0.107 0.226,-0.121C0.209,-0.134 0.204,-0.15 0.204,-0.198L0.666,-0.198C0.667,-0.218 0.667,-0.24 0.667,-0.246C0.667,-0.353 0.651,-0.406 0.609,-0.441C0.566,-0.476 0.51,-0.486 0.35,-0.486C0.196,-0.486 0.137,-0.476 0.091,-0.444C0.041,-0.409 0.027,-0.362 0.027,-0.235C0.027,-0.114 0.041,-0.067 0.087,-0.032C0.13,-0 0.195,0.012 0.332,0.012C0.585,0.012 0.65,-0.02 0.663,-0.151L0.487,-0.151ZM0.204,-0.295C0.206,-0.361 0.234,-0.376 0.353,-0.376C0.423,-0.376 0.459,-0.369 0.475,-0.352C0.486,-0.341 0.488,-0.332 0.489,-0.295L0.204,-0.295Z" style={styleB}/>
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,1461.46,1846)">
                        <path d="M0.093,-0.582L0.093,-0.473L0,-0.473L0,-0.346L0.093,-0.346L0.093,-0.208C0.093,-0.169 0.093,-0.145 0.094,-0.137C0.096,-0.083 0.106,-0.057 0.131,-0.032C0.166,0.002 0.212,0.012 0.325,0.012C0.447,0.012 0.501,-0.003 0.537,-0.048C0.564,-0.083 0.571,-0.112 0.573,-0.202L0.411,-0.202C0.411,-0.153 0.41,-0.149 0.404,-0.138C0.396,-0.121 0.376,-0.114 0.34,-0.114C0.306,-0.114 0.288,-0.121 0.279,-0.137C0.271,-0.149 0.27,-0.156 0.27,-0.208L0.27,-0.346L0.55,-0.346L0.55,-0.473L0.27,-0.473L0.27,-0.582L0.093,-0.582Z" style={styleB}/>
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,1574.96,1846)">
                        <path d="M0.093,-0.582L0.093,-0.473L0,-0.473L0,-0.346L0.093,-0.346L0.093,-0.208C0.093,-0.169 0.093,-0.145 0.094,-0.137C0.096,-0.083 0.106,-0.057 0.131,-0.032C0.166,0.002 0.212,0.012 0.325,0.012C0.447,0.012 0.501,-0.003 0.537,-0.048C0.564,-0.083 0.571,-0.112 0.573,-0.202L0.411,-0.202C0.411,-0.153 0.41,-0.149 0.404,-0.138C0.396,-0.121 0.376,-0.114 0.34,-0.114C0.306,-0.114 0.288,-0.121 0.279,-0.137C0.271,-0.149 0.27,-0.156 0.27,-0.208L0.27,-0.346L0.55,-0.346L0.55,-0.473L0.27,-0.473L0.27,-0.582L0.093,-0.582Z" style={styleB}/>
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,1690.42,1846)">
                        <path d="M0.487,-0.151C0.48,-0.109 0.459,-0.101 0.346,-0.101C0.282,-0.101 0.245,-0.107 0.226,-0.121C0.209,-0.134 0.204,-0.15 0.204,-0.198L0.666,-0.198C0.667,-0.218 0.667,-0.24 0.667,-0.246C0.667,-0.353 0.651,-0.406 0.609,-0.441C0.566,-0.476 0.51,-0.486 0.35,-0.486C0.196,-0.486 0.137,-0.476 0.091,-0.444C0.041,-0.409 0.027,-0.362 0.027,-0.235C0.027,-0.114 0.041,-0.067 0.087,-0.032C0.13,-0 0.195,0.012 0.332,0.012C0.585,0.012 0.65,-0.02 0.663,-0.151L0.487,-0.151ZM0.204,-0.295C0.206,-0.361 0.234,-0.376 0.353,-0.376C0.423,-0.376 0.459,-0.369 0.475,-0.352C0.486,-0.341 0.488,-0.332 0.489,-0.295L0.204,-0.295Z" style={styleB}/>
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,1826.26,1846)">
                        <path d="M0.04,-0.473L0.04,-0L0.217,-0L0.217,-0.196C0.217,-0.269 0.222,-0.295 0.239,-0.318C0.256,-0.34 0.289,-0.352 0.329,-0.352C0.402,-0.352 0.422,-0.333 0.422,-0.263L0.589,-0.263L0.589,-0.295C0.589,-0.437 0.541,-0.485 0.398,-0.485C0.299,-0.485 0.249,-0.462 0.216,-0.402L0.207,-0.402L0.207,-0.473L0.04,-0.473Z" style={styleB}/>
                    </g>
                    <g transform="matrix(196.023,0,0,196.023,1943.09,1846)">
                        <path d="M0.188,-0.428L0.189,-0.443C0.19,-0.505 0.193,-0.516 0.214,-0.527C0.235,-0.538 0.249,-0.539 0.356,-0.539C0.429,-0.539 0.456,-0.538 0.478,-0.532C0.504,-0.526 0.516,-0.51 0.516,-0.481C0.516,-0.441 0.495,-0.426 0.419,-0.414C0.322,-0.399 0.313,-0.397 0.29,-0.387C0.241,-0.367 0.225,-0.334 0.225,-0.252L0.225,-0.226L0.4,-0.226C0.399,-0.258 0.404,-0.262 0.459,-0.272C0.544,-0.286 0.569,-0.291 0.6,-0.301C0.666,-0.322 0.689,-0.366 0.689,-0.474C0.689,-0.644 0.637,-0.677 0.372,-0.677C0.166,-0.677 0.082,-0.659 0.041,-0.606C0.02,-0.578 0.013,-0.542 0.013,-0.463L0.013,-0.428L0.188,-0.428ZM0.207,-0.178L0.207,-0L0.423,-0L0.423,-0.178L0.207,-0.178Z" style={styleB}/>
                    </g>
                </g>
            </g>
        </svg>

    )
}
