import React, {useEffect} from 'react'
import {settings} from '../storage'
import Menu from '../modules/menu'
import {AboutSVG} from '../svg/about'
import photo from '../../public/about-pic.png'

export default function About() {

  useEffect(() => {
    settings.color = '#017BB3'
  })

  return (
    <div className="app">
      <Menu static={true} page="about" />
      <div id="about" className="page_parent_container">
        <div className="page_body_container">
          
            <div className="bg_texture" />
            <div className="page_header">
                <AboutSVG />
              <div id="credits">
                <p>
                  Code: Gustaf/Buchanan <br />
                  Design: Buchanan/Gustaf<br />
                  Lyrics: Buchanan/Lennon
                </p>
              </div>
            </div>
            <div id="about_content" className="content">
              <img id='about_pic' src={photo}  />
              <p>
                Wouldn’t you like to know me better?
                <br />
                A fullstack web developer 
                <br />
                I call San Diego home
                <br />
                I’ve built for mobile and web
                <br />
                as a team and alone
                <br />
                <br />

                Wouldn’t you like to know about the work that I’ve done? 
                <br />
                the frontend?
                <br />
                the backend?
                <br />
                the debugging fun?
                <br />
                <br />

                Wouldn’t you like to know about the tools that help me create?
                <br />
                the APIs?
                <br />
                the Libraries?
                <br />
                and async
                <br />
                <br />
                await 
                <br />
                <br />
                Wouldn’t you like to know what makes me tick? 
                <br />
                Music, movies, electric guitar?
                <br />
                Art, sports, the links that I click?
                <br />
                the abundance of things that help me exist?
                <br />
                and the unfortunate more that were left off this list?
                <br />
                <br />
                Wouldn’t you like to know me better?
                <br />
                Wouldn’t you like to know me better?
                <br />
                Wouldn’t you like to know me better?
                <br />
                Wouldn’t you like to know me better?
              </p>
            </div>
          
        </div>
      </div>
    </div>
  )
}
